<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>Update Labour Account</h2>
    <form [formGroup]="labourAccountForm">
      <h3>Purchase Details</h3>
      <div class="card">
      <div class="form-row">
       
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
            <label for="labourName">Labour Name<span class="requried-field">*</span></label>
            <ng-autocomplete
            [data]="labourName"
            [searchKeyword]="keyword3"
            placeholder="Enter the supplier Name"
            (selected)='selectEvent3($event)'
            (inputChanged)='onChangeSearch3($event)'
            (inputFocused)='onFocused3($event)'
            historyIdentifier="labourName"
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
        
          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.labourName"></a>
          </ng-template>
        
          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
            <div *ngIf="submitted && f['labourName'].errors" class="invalid-feedback">
              <div *ngIf="f['labourName'].errors['required']">Name is required</div>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
            <label for="givenAmount">Total Amount<span class="requried-field">*</span></label>
            <input
              type="number"
              id="givenAmount"
              placeholder="Enter givenAmount"
              class="form-control"
              formControlName="givenAmount"
              [ngClass]="{ 'is-invalid': submitted && f['givenAmount'].errors }"
            />
            <div *ngIf="submitted && f['givenAmount'].errors" class="invalid-feedback">
              <div *ngIf="f['givenAmount'].errors['required']">givenAmount is required</div>
            </div>
        </div>
        <div class="form-group col-sm-12 col-md-6 col-lg-4">
            <label for="balance">Balance<span class="requried-field">*</span></label>
            <input
              type="number"
              id="balance"
              placeholder="Enter balance"
              class="form-control"
              formControlName="balance"
              [ngClass]="{ 'is-invalid': submitted && f['balance'].errors }"
            />
            <div *ngIf="submitted && f['balance'].errors" class="invalid-feedback">
              <div *ngIf="f['balance'].errors['required']">balance is required</div>
            </div>
        </div>
        </div>
        </div>
       
        <h3>Amount Spended</h3>
        <div class="card">
        <div class="form-row">
         
            <div class="scroll1">    
            <table class="custom-table">
              <thead>
                <tr>
                  <th>Amount Spended</th>
                  <th>Project Name</th>
                  <th>SiteNo</th>
                  <th>Reported Date</th>
                  <th>Explanation</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of rows; let i = index" >
                    <td>
                        <input
                  type="number"
                  id="amountSpended"
                  placeholder="Enter amountSpended"
                  class="form-control"
                  formControlName="amountSpended"
                  (click)="amount()"
                  [ngClass]="{ 'is-invalid': submitted && f['reportedDate'].errors }"
                />
                      </td>
                    <td>
                        <ng-autocomplete
                        [data]="projectName"
                        [searchKeyword]="keyword1"
                        placeholder="Enter the  Name"
                        (selected)='selectEvent1($event)'
                        (inputChanged)='onChangeSearch1($event)'
                        (inputFocused)='onFocused1($event)'
                        historyIdentifier="projectName"
                        [itemTemplate]="itemTemplate1"
                        [notFoundTemplate]="notFoundTemplate">
                      </ng-autocomplete>
                    
                      <ng-template #itemTemplate1 let-item>
                        <a [innerHTML]="item.projectName"></a>
                      </ng-template>
                    
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </td>
                    <td>
                        <ng-autocomplete
                        [data]="siteNo"
                        [searchKeyword]="keyword2"
                        placeholder="Enter the  Name"
                        (selected)='selectEvent2($event)'
                        (inputChanged)='onChangeSearch2($event)'
                        (inputFocused)='onFocused2($event)'
                        historyIdentifier="labourName"
                        [itemTemplate]="itemTemplate2"
                        [notFoundTemplate]="notFoundTemplate">
                      </ng-autocomplete>
                    
                      <ng-template #itemTemplate2 let-item>
                        <a [innerHTML]="item.labourName"></a>
                      </ng-template>
                    
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </td>
                    
                        
                <td>
                  <input
            type="date"
            id="reportedDate"
            placeholder="Enter reportedDate"
            class="form-control"
            formControlName="reportedDate"
            [ngClass]="{ 'is-invalid': submitted && f['reportedDate'].errors }"
          />
                </td>
                <td>
                  <input
            type="text"
            id="explanation"
            placeholder="Enter explanation"
            class="form-control"
            formControlName="explanation"
            [ngClass]="{ 'is-invalid': submitted && f['explanation'].errors }"
          />
                </td>
                <td>
                  <mat-icon (click)="add()">add</mat-icon>
                  <mat-icon (click)="getAllData()">save</mat-icon>
                </td>
              </tr>
              <tr *ngFor="let row1 of rows1; let i = index">
                <td *ngIf="this.tablerow == true && this.editrow != true">{{row1.amountSpended}}</td>
                <td *ngIf="this.editrow === true && this.tablerow == true">
            <input
              id="amountSpended"
              placeholder="Enter amountSpended"
              class="form-control"
              formControlName="amountSpended"
              (click)="amount()"
              [ngClass]="{ 'is-invalid': submitted && f['amountStpended'].errors }"
            />
                  </td>
                <td *ngIf="this.tablerow == true && this.editrow != true">{{row1.projectName}}</td>
                <td *ngIf="this.tablerow === true && this.editrow === true">
                    <ng-autocomplete
                    [data]="projectName"
                    [searchKeyword]="row1.amountSpended"
                    placeholder="Enter the  Name"
                    (selected)='selectEvent1($event)'
                    (inputChanged)='onChangeSearch1($event)'
                    (inputFocused)='onFocused1($event)'
                    historyIdentifier="projectName"
                    [itemTemplate]="itemTemplate1"
                    [notFoundTemplate]="notFoundTemplate">
                  </ng-autocomplete>
                
                  <ng-template #itemTemplate1 let-item>
                    <a [innerHTML]="item.projectName"></a>
                  </ng-template>
                
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </td>
                <td *ngIf="this.tablerow == true && this.editrow != true">{{row1.siteNo}}</td>
                <td *ngIf="this.tablerow == true && this.editrow == true">
                    <ng-autocomplete
                    [data]="siteNo"
                    [searchKeyword]="row1.siteNo"
                    placeholder="Enter the  Name"
                    (selected)='selectEvent2($event)'
                    (inputChanged)='onChangeSearch2($event)'
                    (inputFocused)='onFocused2($event)'
                    historyIdentifier="labourName"
                    [itemTemplate]="itemTemplate2"
                    [notFoundTemplate]="notFoundTemplate">
                  </ng-autocomplete>
                
                  <ng-template #itemTemplate2 let-item>
                    <a [innerHTML]="item.labourName"></a>
                  </ng-template>
                
                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </td>
                
                <td *ngIf="this.tablerow == true && this.editrow != true">{{row1.reportedDate}}</td>
                <td *ngIf="this.tablerow == true && this.editrow == true">
                    <input
              
              id="reportedDate"
              placeholder="Enter reportedDate"
              class="form-control"
              formControlName="reportedDate"
              [ngClass]="{ 'is-invalid': submitted && f['reportedDate'].errors }"
            />
                  </td>
                <td *ngIf="this.tablerow == true && this.editrow != true">{{row1.explanation}}</td>
              
              
                
                    
           
            <td *ngIf="this.tablerow == true && this.editrow == true">
              <input
       
        id="explanation"
        placeholder="Enter explanation"
        class="form-control"
        formControlName="explanation"
        [ngClass]="{ 'is-invalid': submitted && f['explanation'].errors }"
      />
            </td>
                <td>
                    <mat-icon (click)="edit()">edit</mat-icon>

                    <mat-icon (click)="getAllData()" >save</mat-icon>
                  </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>

      
    <div class="formbutton">
      <button type="submit" class="btn btn-primary" (click)="onSubmit(labourAccountForm.value)">Submit</button>
      <button type="submit" class="btn btn-danger btn2" (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  