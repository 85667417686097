import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { AuthService } from '../../../services/auth.service';
import { NotificationService } from '../../../services/notificationService';
import { DataService } from '../data.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent {
  project: any = {};
  states: string[] = ['State 1', 'State 2', 'State 3'];
  purchaseMode:string[]=['debit','credit']
  paymentMode:string[]=['cash','checque']
  labourAccountForm: FormGroup = new FormGroup({});
  submitted: boolean = false;
  bill:string|undefined;
  editrow:boolean= false;
  total:any[]=[];
  rows1:any[]=[];
  uid:any;
  keyword1 = 'projectName'
  keyword2 = 'siteNo'
  keyword3 = 'labourName'
  product:string[]=[]
  ispurchase:boolean = false;
  ispayment:boolean = false;
  isEnabled:boolean = false;
  isDisabled = false;
  productName:any[]=[];
  projectName:any[]=[];
  siteNo:any[]=[];
  labourName:any[]=[];
  rows: FormGroup[];
  productDetails:any[]=[];
  supplierName:any[]=[];
  gst=false;
  btnEnable:boolean=false;
  iscredit:boolean=false;
  isdebit:boolean=false;
  iscash:boolean=false;
  ischeque:boolean=false;
  category:any[]=[];
  id:any;
 tablerow:boolean=false;
  

  constructor(private data: DataService,
    private toastr:NotificationService,
    private formBuilder: FormBuilder,
    private router:Router,
    private route:ActivatedRoute,
    private auth:AuthService,
  ) {
    this.rows = [];
    this.addRow();
    //this.calculate();
  }

  ngOnInit()  {
    if (this.route.snapshot.queryParamMap.get("id")) {
      // @ts-ignore TS2322
      this.id = this.route.snapshot.queryParamMap.get("id");
      this.getById(this.id);
    }

    this.labourAccountForm= this.formBuilder.group({
      labourName:['',Validators.required],
      givenAmount:['',Validators.required],
      balance:['',Validators.required],
      amountSpended:[''],
      projectName:[''],
      siteNo:[''],
      reportedDate:[''],
      explanation:['']

    });

    this.auth.getUser().subscribe((user:any)=>{
      if(user){
        console.log(user.multiFactor.email)
      this.uid=user.uid
      }
    })
   
  }
 // get productDetails() { return this.labourAccountForm.get('productDetails') as FormArray; }

  addRow(){
 
    const row = this.formBuilder.group({
      projectName: ['', Validators.required],
      siteNo:['',Validators.required],
      amountSpended:['',Validators.required],
      reportedDate:['',Validators.required],
      explanation:['',Validators.required]
    });
    this.rows.push(row);
    
  }
  add() {
      this.addRow();
  }
  getAllData(){
    this.productDetails.push({
      projectName: this.labourAccountForm.get('projectName')?.value,
      siteNo:this.labourAccountForm.get('siteNo')?.value,
      amountSpended:this.labourAccountForm.get('amountSpended')?.value,
      reportedDate:this.labourAccountForm.get('reportedDate')?.value,
      explanation:this.labourAccountForm.get('explanation')?.value
    })
  }
getById(_id:any){
    this.data.getById(_id).subscribe((res:any)=>{
      console.log(res,"res")
     if(res){
      //console.log(this.categoryData,"concel")
      this.updateView(res)
     }
    })
    
  }
  updateView(data:any){
    console.log(data,"Edit")
    this.labourAccountForm.patchValue({
      labourName:data.labourName,
      givenAmount:data.givenAmount,
      balance:data.balance,
    })
    this.tablerow = true;
    data.amountSpended.forEach((val:any)=>{
      this.rows1.push(val)
    })
  }
  remove(index: number) {
    if (this.rows.length > 1) {
      this.rows.splice(index, 1);
    }
  }
  onCancel(){
    this.labourAccountForm.reset()
  }
  edit(){
 this.editrow = true;
  }


  goBack() {
    this.router.navigate(['/dashboard/labourAccount']);
  }
  get f() {
    return this.labourAccountForm.controls;
  }
  onSubmit(data: any) {
    console.log(data)
    if (this.labourAccountForm.invalid) {
      this.toastr.showError("Error", "");
      return;
    }

    data.updateOn = moment().format()
    data.amountSpended = this.productDetails
    //data.isActive = true
    console.log(data,"formData")
    this.data.update(data,this.id).subscribe((res:any)=>{
      console.log('Purchase Created!',res);
      this.toastr.showSuccess("Successfully!!","Purchase Added")
      this.router.navigate(['/dashboard/labourAccount'])
    })
  }

  selectEvent3(item:any) {
    // do something with selected item
    console.log(item)
   
    this.labourAccountForm.patchValue({
     labourName:item.labourName
    })
  }
 
  onChangeSearch3(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getProduct().subscribe((res:any)=>{
      this.labourName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      console.log(this.product)
      //this.productName = [];
      
       //this.product.forEach((val:any)=>{
        return this.labourName.filter(val  =>  val.productName.toLowerCase().includes(search));
      //   if (val.productName && val.productName.toLowerCase().trim() === search.toLowerCase().trim()) {
      //     this.productName.push(val);
      //     console.log(this.productName);
      // }
      //})

    })
  }

  onFocused3(e:any) {
    console.log(e)
    // do something
  }
  selectEvent1(item:any) {
    // do something with selected item
    console.log(item)
   
    this.labourAccountForm.patchValue({
      projectName:item.projectName
    })
    this.data.getSite().subscribe((res:any)=>{
      this.projectName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.projectName)
          this.projectName.forEach((val:any)=>{
            if(val.siteNo == item.noofsite){
              this.siteNo.push(val.siteNo)
            }
          })
    })
  }
 
  onChangeSearch1(search: string) {
    // fetch remote data from here
    console.log(search)
    search.toLowerCase();
    this.data.getSite().subscribe((res:any)=>{
      this.projectName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
          console.log(this.projectName)
          
        return this.projectName.filter( val  =>   val.toLowerCase().includes(search));
    })
  }

  onFocused1(e:any) {
    console.log(e)
    // do something
  }
  selectEvent2(item:any) {
    // do something with selected item
    console.log(item)
   
    this.labourAccountForm.patchValue({
      projectName:item.projectName
    })
  }
 
  onChangeSearch2(search: string) {
    // fetch remote data from here
    console.log(search)
    search;
        return this.siteNo.filter( val  =>   val.includes(search));
  }

  onFocused2(e:any) {
    console.log(e)
    // do something
  }
  selectEvent4(item:any) {
    // do something with selected item
    console.log(item)
    
   
    this.labourAccountForm.patchValue({
      supplierName:item.supplierName
    })
    this.data.getProduct().subscribe((res:any)=>{
      this.productName = Object.keys(res).map(key => ({ id: key, ...res[key] }));
      this.productName.forEach((val:any)=>{
        if(val.supplierName.toLowerCase() == item.supplierName.toLowerCase()){
        this.category.push(val)
        console.log(this.category)
        }
      })
    })
  }
  getData(){
    this.total.push(this.labourAccountForm.get('projectName')?.value)
    console.log(this.total.length)
    this.labourAccountForm.patchValue({
      totalProduct:this.total.length
    })
  }
 
  
  fieldEnable(){
   this.btnEnable = true
  }
 amount(){
  const balance = this.labourAccountForm.get('balance')?.value | 0
  const amtSpnd = this.labourAccountForm.get('amountSpended')?.value | 0
  if(balance<0){
    
    this.labourAccountForm.patchValue({
      balance:amtSpnd
    })
  } else {
    const totalAmount = Number(balance) + Number(amtSpnd)
    this.labourAccountForm.patchValue({
      balance:totalAmount
    })
  }
}
}
