import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notificationService';
import { DataService } from './data.service';


@Component({
  selector: 'app-labour-account',
  templateUrl: './labour-account.component.html',
  styleUrl: './labour-account.component.scss'
})
export class LabourAccountComponent {
  labourList:any[]=[];
  defaultPagination: string | undefined = undefined;
  page: number = 1;
  _itemsPerPage: any = "25";
  isChecked: boolean | undefined = false;
  checkedIdList: any[] = [];
  id: string = "";
  selectedId: string[] = [];
  constructor(private router:Router,
    private data:DataService,
    private toastr:NotificationService
  ) { }

  ngOnInit() {
   this.getAll()
  }

  getAll(){
    this.data.get().subscribe((val:any)=>{
      if (val) {
        this.labourList = Object.keys(val).map(key => ({ id: key, ...val[key] }));
        console.log(this.labourList)
      }
      })
  }

  refreshPage(){
    this.getAll();
  }

  add(){
    this.router.navigate(['/dashboard/labourAccount/add']);
  }
  onDelete(_id:string) {
   this.data.deleteById(_id).subscribe(()=>{
    this.toastr.showError("Deleted","Successfully");
    this.refreshPage();
   })
  }

  onEdit(_id:string) {
    this.router.navigate(['/dashboard/labourAccount/edit'], {
      queryParams: { id: _id }

    });
  }

  getSelectedId(isCheckedStatus: boolean, id: string) {
    if (isCheckedStatus === true) {
      this.id = id;
      this.isChecked = isCheckedStatus;
      this.checkedIdList.push(this.id);
    } else {
      this.isChecked = undefined;
      this.id = "";
      const index = this.checkedIdList.indexOf(this.id);
      this.checkedIdList.splice(index);
    }
  }
  toggleStatus(element: any): void {
    console.log(element)
    element.isActive = !element.isActive;
    
    // Here you can also make an API call to update the status in your backend
  }
}
