<div class="container-fluid">
    <h3>Filters</h3>
    <div class="filters">
        
      
        <label for="supplier">Category</label>
        <input type="text" class="dropdown"/>
        <label for="supplier">Name</label>
        <input type="text" class="dropdown"/>
        <label for="supplier">Product Name</label>
        <input type="text" class="dropdown"/>
        <label for="category">Page Size</label>
        
        <select id="category" class="dropdown">
            <!-- Add options here -->
        </select>
        <button id="addButton" class="btn btn-primary add-button" (click)="add()">Add +</button>
    </div>
    <div class="scroll">
        <table
    class="custom-table">
    <thead>
      <tr>
        <th>Labour</th>
        <th>Total Amount</th>
       
        <th>Amount Spent</th>
        <th>Debit</th>
        <th>Credit</th>
        <th>Action</th>
        
        </tr>
        </thead>
        <tbody >
            <tr *ngFor="
            let element of labourList
            | paginate: { itemsPerPage: 25, currentPage: page }
          "
            >
                
              <td>{{element.labourName}}</td>
              <td>{{element.givenAmount}}</td>
              <td *ngFor="let ele of element.amountSpended">
                <label>{{ele.amountSpended}} | ProjectName:{{ele.projectName}}</label>
              </td>
              <td>{{element.debit}}</td>
              <td>{{element.credit}}</td>
              <!-- <td>{{element.pstatus}}</td> -->
              
              <td>
              
                <mat-icon (click)="onEdit(element.id)">edit</mat-icon>
                <mat-icon (click)="onDelete(element.id)" >delete</mat-icon>
               
              </td>
             
            </tr>
            </tbody>
            </table>
     </div>
</div>